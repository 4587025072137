import {
  MCIntelligenceMenuLink,
  MCProLogo,
  MCProMenuLink,
  MorningConsultMLogo,
  ProductHeader,
  ProductMenu,
} from "@mci/cross-platform-components";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useSideNavigationAnalytics } from "~/components/Layout/LayoutSideNavigation/useSideNavigationAnalytics";
import { useIsNavigationExpanded } from "~/components/PersistentState";
import { commonConfig } from "~/config/common-config";
import { getValueOrDefault } from "~/lib/shared/getValueOrDefault";
import {
  getUserPermissionsForMci,
  userHasMcIntelAccess,
} from "~/lib/shared/userHasMcAppAccess";
import { MorningConsultLogo } from "~/ui-library/logos";

export const SideNavigationProductMenu = ({
  isOpen,
  setIsOpen,
}: {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}) => {
  const trackNavItemClicked = useSideNavigationAnalytics();
  const { isNavigationExpanded } = useIsNavigationExpanded();
  const { data: session, status } = useSession();

  if (status !== "authenticated") {
    return (
      <ProductHeader
        as={Link}
        className={
          getValueOrDefault(isNavigationExpanded, true)
            ? "justify-between"
            : "justify-center"
        }
        href="/"
        collapsedAppLogo={<MorningConsultMLogo data-testid="collapsed-logo" />}
        expandedAppLogo={<MorningConsultLogo data-testid="expanded-logo" />}
        data-testid="product-menu-header"
      />
    );
  }

  const hasMciAccess = userHasMcIntelAccess(getUserPermissionsForMci(session));

  return (
    <ProductMenu
      className={
        getValueOrDefault(isNavigationExpanded, true)
          ? "justify-between"
          : "justify-center"
      }
      collapsedAppLogo={<MorningConsultMLogo data-testid="collapsed-logo" />}
      expandedAppLogo={<MCProLogo data-testid="expanded-logo" />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      data-testid="product-menu"
    >
      <MCProMenuLink
        as={Link}
        isActive
        href="/"
        onClick={() => {
          trackNavItemClicked("Morning Consult Pro");
        }}
      />
      <MCIntelligenceMenuLink
        as={Link}
        href={
          hasMciAccess
            ? commonConfig.NEXT_PUBLIC_MCI_URL
            : "/morning-consult-intelligence"
        }
        onClick={() => {
          trackNavItemClicked("Morning Consult Intelligence");
        }}
      />
    </ProductMenu>
  );
};
