import { GoogleTagManager } from "@next/third-parties/google";
import { SessionProvider } from "next-auth/react";
import { DefaultSeo } from "next-seo";
import { AnalyticsProvider } from "~/components/Analytics";
import { DataDogRum } from "~/components/DataDogRum";
import { ErrorBoundary } from "~/components/ErrorBoundary";
import { FirstLoginModal } from "~/components/FirstLoginModal";
import { GlobalStateProvider } from "~/components/GlobalState";
import { Layout } from "~/components/Layout";
import { NavigationProvider } from "~/components/NavigationProvider/NavigationProvider";
import { PermissionsSync } from "~/components/PermissionsSync";
import { PersistentStateProvider } from "~/components/PersistentState";
import { PianoLoader } from "~/components/Piano";
import { ExecutePiano } from "~/components/Piano/ExecutePiano";
import { ProcessLogin } from "~/components/ProcessLogin";
import { RegisterAccountModal } from "~/components/RegisterAccountModal";
import { RouteChangeHandler } from "~/components/RouteChangeHandler";
import { SessionManager } from "~/components/SessionManager";
import { ToastManager } from "~/components/ToastManager";
import { commonConfig } from "~/config/common-config";
import { SEOConfig } from "~/config/next-seo.config";
import Error500 from "~/pages/500.page";

import { AppPropsWithLayout } from "~/types/endil/AppPropsWithLayout";

import "../styles/globals.css";

export default function App({
  Component,
  pageProps,
  router,
}: AppPropsWithLayout) {
  const executePiano = pageProps.meta?.piano ?? { type: "simple" };
  const searchValue = router.query?.query as string | undefined;
  const renderedComponentWithLayout = Component.getLayout?.(
    <ErrorBoundary
      fallback={<Error500 />}
      context={{ component: "App > Custom Layout" }}
    >
      <Component {...pageProps} />
      {!!commonConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && (
        <GoogleTagManager
          gtmId={commonConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}
        />
      )}
    </ErrorBoundary>,
    {
      Component,
      pageProps,
    }
  ) ?? (
    <Layout>
      <ErrorBoundary
        fallback={<Error500 />}
        context={{ component: "App > Default Layout" }}
      >
        <Component {...pageProps} />
        {!!commonConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID && (
          <GoogleTagManager
            gtmId={commonConfig.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}
          />
        )}
      </ErrorBoundary>
    </Layout>
  );
  return (
    <ErrorBoundary context={{ component: "App > Global" }}>
      <PersistentStateProvider>
        <GlobalStateProvider>
          <DataDogRum />
          {/*
        
        Note that our JWTs are valid for 1hr after creation
        
        By polling the session, we check for refreshed values
        every few minutes to make sure the values are updated
        under the hood.
        */}
          <SessionProvider
            session={pageProps.session}
            refetchOnWindowFocus
            refetchWhenOffline={false}
            refetchInterval={270}
          >
            <SessionManager />
            <AnalyticsProvider>
              <DefaultSeo {...SEOConfig} />
              <NavigationProvider
                initialState={{
                  searchValue,
                }}
              >
                <PianoLoader
                  pianoApplicationId={
                    commonConfig.NEXT_PUBLIC_PIANO_APPLICATION_ID
                  }
                  isSandbox={
                    commonConfig.NEXT_PUBLIC_ENDIL_ENV !== "production"
                  }
                >
                  <ProcessLogin />
                  <RouteChangeHandler />
                  <PermissionsSync />
                  {renderedComponentWithLayout}
                  <ToastManager />
                  <RegisterAccountModal />
                  <FirstLoginModal />
                  <ExecutePiano {...executePiano} />
                </PianoLoader>
              </NavigationProvider>
            </AnalyticsProvider>
          </SessionProvider>
        </GlobalStateProvider>
      </PersistentStateProvider>
    </ErrorBoundary>
  );
}
