import { SessionContextValue } from "next-auth/react";
import { LoginButton } from "~/components/LoginButton";
import { ButtonLink } from "~/ui-library/components/ButtonLink/ButtonLink";

export type LayoutFooterLogoToolbarProps = {
  status: SessionContextValue["status"] | "subscribed";
  className?: string | undefined;
};

export type LayoutFooterContainerProps = {
  children?: React.ReactNode;
  className?: string | undefined;
};

const SignInToolbarButton: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => (
  <LoginButton
    behavior={{ type: "window" }}
    disabled={disabled}
    size="extraSmall"
    variant="subtle"
  >
    Sign in
  </LoginButton>
);

const SubscribeButton: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => (
  <ButtonLink
    disabled={disabled}
    size="extraSmall"
    href="/subscribe"
    variant="link"
  >
    Subscribe
  </ButtonLink>
);

const ContactButton: React.FC = () => (
  <ButtonLink
    className="font-semibold text-gray-900"
    href="mailto:pro@morningconsult.com"
    size="extraSmall"
    variant="subtleLink"
  >
    Contact Us
  </ButtonLink>
);

export const LayoutFooterLogoToolbar: React.FC<
  LayoutFooterLogoToolbarProps
> = ({ status }) => {
  if (status === "subscribed") {
    return (
      <div className="flex justify-center gap-m md:gap-xs">
        <ContactButton />
      </div>
    );
  }

  if (status === "authenticated") {
    return (
      <div className="flex justify-center gap-m md:gap-xs">
        <SubscribeButton />
        <ContactButton />
      </div>
    );
  }

  if (status === "unauthenticated") {
    return (
      <div className="flex justify-center gap-m md:gap-xs">
        <ContactButton />
        <SubscribeButton />
        <SignInToolbarButton />
      </div>
    );
  }

  return <div className="justify-center text-center" />;
};
