import React from "react";
import { useAnalytics } from "~/components/Analytics";
import { Footer, FooterLink } from "~/components/Footer";

export interface LayoutFooterProps<T extends React.ElementType> {
  as?: T;
}

export function LayoutFooter<T extends React.ElementType = "header">({
  as,
}: LayoutFooterProps<T>) {
  const { analyticsEnhancedTrack } = useAnalytics();
  const mciOnClick = () => analyticsEnhancedTrack("MCI Click");

  return (
    <Footer as={as}>
      <FooterLink href={"/our-methodology"}>About</FooterLink>
      <FooterLink href={"https://morningconsult.com/company/careers/"}>
        Careers
      </FooterLink>
      <FooterLink href={"/subscribe"}>Pro</FooterLink>
      <FooterLink href={"/morning-consult-intelligence"} onClick={mciOnClick}>
        Morning Consult Intelligence
      </FooterLink>
      <FooterLink href={"/research-solutions"}>Research Solutions</FooterLink>
      <FooterLink href={"/request-a-demo-main"}>Request a Demo</FooterLink>
    </Footer>
  );
}
